import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { getDownloadList } from '@/service/index.js';
import { ElLoading } from 'element-plus';
import { alertMessage } from '@/assets/js/tool.js';
import MyTable from '@/components/MyTable.vue';
export default {
  components: {
    MyTable
  },
  setup() {
    //data
    const {
      t
    } = useI18n();
    const actived = ref('IDE');
    const downloadList = ref([]);
    const tableList = ref([]);
    const ideList = ref([]);
    const pcList = ref([]);
    const androidList = ref([]);
    const loading = ElLoading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0)'
    });

    //watch
    watch(actived, newTab => {
      if (newTab === 'IDE') {
        tableList.value = ideList.value;
      } else if (newTab === 'PC') {
        tableList.value = pcList.value;
      } else {
        tableList.value = androidList.value;
      }
    });

    //获取下载列表
    getDownloadList().then(res => {
      loading.close();
      if (res.status === 0) {
        downloadList.value = res.data;
      } else {
        let text = res ? res.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    });
    watch(downloadList, newList => {
      ideList.value = newList.filter(item => item.downloadFileType === 'IDE');
      // ideList.value[1].description = '1.全新UI设计全新UI设计全新UI设计全新UIUI设计。 \n2.新增两套控件皮肤肤件皮肤。\n3.新增仪表盘、窗帘等5种控件新增仪表盘、窗帘等新增仪表盘。\n4.全新UI设计全新UI设计全新UI设计全新UIUI设计。';
      pcList.value = newList.filter(item => item.downloadFileType === 'APP' && item.platform === 'Windows');
      androidList.value = newList.filter(item => item.downloadFileType === 'APP' && item.platform === 'Android');
      tableList.value = ideList.value;
    });
    return {
      actived,
      tableList
    };
  }
};