import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "history"
};
const _hoisted_2 = {
  class: "history-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_my_table = _resolveComponent("my-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h1", null, _toDisplayString(_ctx.$t('message.more')), 1), _createVNode(_component_el_tabs, {
    modelValue: $setup.actived,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.actived = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "IDE",
      name: "IDE"
    }), _createVNode(_component_el_tab_pane, {
      label: _ctx.$t('message.pc'),
      name: "PC"
    }, null, 8, ["label"]), _createVNode(_component_el_tab_pane, {
      label: "Android",
      name: "Android"
    })]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_my_table, {
    options: $setup.tableList
  }, null, 8, ["options"])])]);
}